import BaseBean from "@/utils/BaseBean";

export interface IHdxxCardDataObj {
    utilInst:HdxxCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class HdxxCardUtil extends BaseBean{
    public dataObj:IHdxxCardDataObj;

    constructor(proxy:any,dataObj:IHdxxCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //货代公司
        this.dataObj.otherParams.hdgsData=await this.utils.OrderProviderApi.getAllHdgs({});
    }
}